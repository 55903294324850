<template>
  <div>
    <b-row>
      <b-col cols="7">
        <div class="areawrap">
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-end px-4">
                <h2 class="mb-0 mr-4">
                  Customer Information
                </h2>
                <b-link
                  v-if="clinicData.status === 0"
                  class="text-gray-500"
                  href="#"
                  @click.prevent="openInformationModal"
                >
                  <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
                </b-link>
                <span class="text-primary font-size-sm ml-auto">
                  Date Added
                </span>
                <span class="font-size-base font-weight-normal ml-2">
                  {{ $dayjs(clinicData.created_at).format('MMM D, YYYY') }}
                </span>
              </div>
              <hr>
              <b-form-group label="Clinic Name" label-class="text-primary mb-1">
                {{ clinicData.clinic_name }}

                <span
                  v-if="clinicData.status === 0"
                  class="d-inline-block bg-primary text-white rounded-pill px-2 py-0 ml-4"
                >
                  Pending
                </span>
                <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0 ml-4">
                  Active
                </span>
              </b-form-group>
              <b-form-group label="Address" label-class="text-primary mb-1">
                <span class="d-block">{{ clinicData.clinic_address }}</span>
                <span class="d-block">{{ clinicData.clinic_city }}, {{ country }}</span>
              </b-form-group>
              <b-form-group label="Telephone" label-class="text-primary mb-1">
                {{ clinicData.clinic_telphone }}
              </b-form-group>
              <b-form-group label="Facility" label-class="text-primary mb-1">
                {{ facility }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <div class="d-flex align-items-end px-4">
                <h2 class="mb-0 mr-4">
                  Owner Information
                </h2>
                <b-link v-if="clinicData.status === 0" class="text-gray-500" href="#" @click.prevent="openOwnerModal">
                  <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
                </b-link>
              </div>
              <hr>
              <b-form-group label="Owner" label-class="text-primary mb-1">
                {{ clinicData.owner_name || '-' }}
              </b-form-group>
              <b-form-group label="Email" label-class="text-primary mb-1">
                {{ clinicData.owner_email || '-' }}
              </b-form-group>
              <b-form-group label="Telephone" label-class="text-primary mb-1">
                {{ clinicData.owner_extension || '-' }}
              </b-form-group> <b-form-group label="Mobile" label-class="text-primary mb-1">
                {{ clinicData.owner_mobile || '-' }}
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <h2 class="px-4">
                Contact
              </h2>
              <hr>
              <b-form-group label="Contact" label-class="text-primary mb-1">
                {{ clinicData.contact_name || '-' }}
              </b-form-group>
              <b-form-group label="Email" label-class="text-primary mb-1">
                {{ clinicData.contact_email || '-' }}
              </b-form-group>
              <b-form-group label="Telephone" label-class="text-primary mb-1">
                {{ clinicData.contact_extension || '-' }}
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-col cols="5">
        <div class="areawrap">
          <div class="d-flex align-items-end px-4">
            <h2 class="mb-0 mr-4">
              Autoclaves
            </h2>
            <b-link
              class="text-gray-500 ml-auto"
              href="#"
              @click.prevent="openCreateAutoclaveModal"
            >
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
            </b-link>
          </div>
          <hr>
          <div v-for="autoclave in autoclaveList" :key="autoclave.id" class="d-flex mb-5">
            <span class="d-inline-block monospace">
              {{ autoclave.serial_number }}
            </span>
            <span
              v-if="autoclave.linked === 0"
              class="d-inline-block bg-gray-500 text-white rounded-pill px-2 py-0 ml-4"
            >
              Unlinked
            </span>
            <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0 ml-4">
              Linked
            </span>

            <span v-if="autoclave.warranty" class="d-inline-block bg-info text-white rounded-pill px-2 py-0 ml-4">
              {{ autoclave.warranty }}
            </span>

            <b-link
              v-if="autoclave.linked === 0"
              class="text-gray-500 ml-auto"
              href="#"
              @click.prevent="openDeleteAutoclaveModal(autoclave)"
            >
              <font-awesome-icon :icon="['fas', 'trash']" fixed-width />
            </b-link>
            <b-link
              v-else
              class="text-gray-500 ml-auto"
              href="#"
              @click.prevent="openInformationModal"
            >
              <font-awesome-icon :icon="['fas', 'bell']" fixed-width />
            </b-link>
            <b-link
              class="text-gray-500 ml-4"
              href="#"
              @click.prevent="openEditAutoclaveModal(autoclave)"
            >
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
            </b-link>
          </div>
        </div>
      </b-col>
    </b-row>

    <information-modal ref="informationModal" @update="getClinicData" />
    <owner-modal ref="ownerModal" @update="getClinicData" />
    <create-autoclave-modal ref="createAutoclaveModal" :clinic-id="id" @update="getAutoclaveList" />
    <delete-autoclave-modal ref="deleteAutoclaveModal" @update="getAutoclaveList" />
    <edit-autoclave-modal ref="editAutoclaveModal" @update="getAutoclaveList" />
  </div>
</template>

<script>
import countryList from '@/common/countryList'
import facilityList from '@/common/facilityList'
import { getClinicData } from '@/api/clinic'
import { getAutoclaveList } from '@/api/autoclave'
import informationModal from '@/components/clinic/informationModal'
import ownerModal from '@/components/clinic/ownerModal'
import createAutoclaveModal from '@/components/clinic/createAutoclaveModal'
import deleteAutoclaveModal from '@/components/clinic/deleteAutoclaveModal'
import editAutoclaveModal from '@/components/clinic/editAutoclaveModal'

export default {
  name: 'ClinicDetail',
  components: {
    informationModal,
    ownerModal,
    createAutoclaveModal,
    deleteAutoclaveModal,
    editAutoclaveModal
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      clinicData: {},
      autoclaveList: []
    }
  },
  computed: {
    countryList () {
      return countryList
    },
    facilityList () {
      return facilityList
    },
    country () {
      return countryList.find(item => item.value === this.clinicData.clinic_country)?.text
    },
    facility () {
      let text = facilityList.find(item => item.value === this.clinicData.facility)?.text
      if (this.clinicData.facility === 99) text += ` - ${this.clinicData.facility_other}`
      return text
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getClinicData()
      this.getAutoclaveList()
    },
    getClinicData () {
      return getClinicData(this.id).then(res => {
        this.clinicData = res.data.data.clinic
      })
    },
    getAutoclaveList () {
      return getAutoclaveList(this.id).then(res => {
        this.autoclaveList = res.data.data
      })
    },

    openInformationModal () {
      const data = {
        id: this.clinicData.id,
        clinic_name: this.clinicData.clinic_name,
        clinic_country: this.clinicData.clinic_country,
        clinic_city: this.clinicData.clinic_city,
        clinic_address: this.clinicData.clinic_address,
        clinic_telphone: this.clinicData.clinic_telphone,
        facility: this.clinicData.facility,
        facility_other: this.clinicData.facility_other
      }
      this.$refs.informationModal.show(data)
    },
    openOwnerModal () {
      const data = {
        id: this.clinicData.id,
        owner_name: this.clinicData.owner_name,
        owner_email: this.clinicData.owner_email,
        owner_extension: this.clinicData.owner_extension,
        owner_mobile: this.clinicData.owner_mobile
      }
      this.$refs.ownerModal.show(data)
    },
    openCreateAutoclaveModal () {
      this.$refs.createAutoclaveModal.show()
    },
    openDeleteAutoclaveModal (data) {
      this.$refs.deleteAutoclaveModal.show(data)
    },
    openEditAutoclaveModal (data) {
      this.$refs.editAutoclaveModal.show(data)
    }
  }
}
</script>
