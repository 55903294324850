<template>
  <b-modal
    id="create-autoclave-modal"
    v-model="showForm"
    title="Add New Autoclave"
    cancel-title="CANCEL"
    ok-title="ADD"
    hide-header-close
    centered
    static
    @ok="confirm"
  >
    <div class="px-4">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Model"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.model.$anyError : null"
          >
            <b-form-select
              v-model="form.model"
              :options="modelList"
              :state="$v.form.$anyDirty ? !$v.form.model.$anyError : null"
            >
              <template #first>
                <b-form-select-option :value="null">
                  Select model
                </b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.model.required : null">
              model is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Serial Number"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.serial_number.$anyError : null"
          >
            <b-form-input v-model="form.serial_number" placeholder="Enter Serial Number" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.serial_number.required : null">
              serial_number is required
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.serial_number.repeated : null">
              serial number is repeated
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Warranty"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.warranty.$anyError : null"
          >
            <b-form-datepicker
              v-model="form.warranty"
              class="mb-2"
              placeholder="Warranty Date"
              :date-format-options="dateFormat"
              :state="$v.form.$anyDirty ? !$v.form.warranty.$anyError : null"
            />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.warranty.required : null">
              warranty is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Work Station (Optional)">
            <b-form-input v-model="form.work_station" placeholder="Enter Work Station" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <confirm-modal ref="confirmModal" @submit="submit" @cancel="showForm = true" />
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import modelList from '@/common/modelList'
import { createAutoclave } from '@/api/autoclave'
import ConfirmModal from '@/components/clinic/confirmModal'

export default {
  name: 'CreateAutoclaveModal',
  components: {
    ConfirmModal
  },
  props: {
    clinicId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      form: {
        model: null,
        serial_number: '',
        warranty: null,
        work_station: ''
      },
      autoclaveRepeatList: [],
      showForm: false
    }
  },
  validations () {
    return {
      form: {
        model: { required },
        serial_number: {
          required,
          repeated (value) {
            if (!this.form.serial_number) return true
            return this.autoclaveRepeatList.indexOf(value) === -1
          }
        },
        warranty: { required }
      }
    }
  },
  computed: {
    modelList () {
      return modelList
    },

    dateFormat () {
      return { year: 'numeric', month: 'numeric', day: 'numeric' }
    }
  },
  methods: {
    show () {
      this.form = {
        model: null,
        serial_number: '',
        warranty: null,
        work_station: ''
      }
      this.$v.form.$reset()
      this.showForm = true
    },
    hide () {
      this.showForm = false
    },
    confirm (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        this.showForm = true
      } else {
        this.hide()
        this.$refs.confirmModal.show([this.form])
      }
    },
    submit () {
      this.showForm = true
      const data = {
        clinic_id: this.clinicId,
        autoclaves: [this.form]
      }
      createAutoclave(data).then(res => {
        if (res.data.success) {
          this.$emit('update')
          this.hide()
        } else {
          if (this.autoclaveRepeatList.indexOf(this.form.serial_number) !== -1) return
          this.autoclaveRepeatList.push(this.form.serial_number)
        }
      })
    }
  }
}
</script>
