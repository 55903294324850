<template>
  <b-modal
    id="delete-autoclave-modal"
    title="Delete Autoclave"
    cancel-title="CANCEL"
    ok-title="DELETE"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <p class="mb-4">
        Are you sure you want to delete this autoclave? This cannot be undone.
      </p>
      <p class="text-primary">
        {{ autoclaveData.serial_number }}
      </p>
    </div>
  </b-modal>
</template>

<script>
import { deleteAutoclave } from '@/api/autoclave'

export default {
  name: 'DeleteAutoclaveModal',
  data () {
    return {
      autoclaveData: {}
    }
  },
  methods: {
    show (data) {
      this.$bvModal.show('delete-autoclave-modal')
      this.autoclaveData = data
    },
    hide () {
      this.$bvModal.hide('delete-autoclave-modal')
    },
    submit () {
      deleteAutoclave(this.autoclaveData.id).then(() => {
        this.$emit('update')
      })
    }
  }
}
</script>
